<template>
  <div class="misc-wrapper bg-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="logoUrl"
        alt="Payrite"
        style="width:150px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <b-card>
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Jij bent niet geautoriseerd!
          </h2>
          <p class="mb-2">
            U heeft geen toestemming om deze pagina te openen.
          </p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="loginRoute()"
          >Terug</b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BImg,
  BButton,
  BCard,
} from 'bootstrap-vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BLink, BImg, BButton, BCard,
  },
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
    }
  },
  computed: {
    logoUrl() {
      return this.logoImg
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.bg-wrapper{
  background: url("../../../assets/images/pages/lostpassword.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
